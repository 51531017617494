import { http } from '~/utils/http'
import type { PageRequest, PageResponse, Response } from '~/type'
import type { MemberNoticeDTO, MessageNoticeDTO, UnreadCount } from '~/type/noticeCenter'

// 平台消息，优惠通知获取
export const memberNoticePage = async (data: PageRequest = {}) => {
  return await http<PageResponse<MemberNoticeDTO>>('/app-api/member/notice/page', {
    method: 'post',
    body: data
  }).then((res) => {
    return res
  })
}

// 平台消息，优惠通知已读
export const memberNoticeRead = async (data: { paramList: number[] }) => {
  return await http<Response>('/app-api/member/notice/read', {
    method: 'post',
    body: data
  })
}

// 平台消息，优惠通知删除消息
export const deleteNotice = async (data: { paramList: number[] }) => {
  return await http<Response>('/app-api/member/notice/delete', {
    method: 'post',
    body: data
  })
}

// 平台消息，优惠通知未读
export const getNoticeUnreadCount = async (messageType: number) => {
  return memberNoticePage({ size: 1, current: 1, model: { messageType: messageType } })
}

//站内信分页列表
export const notifyMessageQuery = async (data: PageRequest = {}) => {
  return await http<PageResponse<MessageNoticeDTO>>('/app-api/system/notify/message/query', {
    method: 'post',
    body: data
  }).then((res) => {
    return res
  })
}

// 设置消息已读
export const updateMessageRead = async (data: { paramList: number[] }) => {
  return await http<Response>('/app-api/system/notify/message/update-read', {
    method: 'post',
    body: data
  })
}

// 删除消息
export const deleteMessage = async (data: { paramList: number[] }) => {
  return await http<Response>('/app-api/system/notify/message/delete', {
    method: 'post',
    body: data
  })
}

// 获取未读消息数量
export const getMessageUnreadCount = async () => {
  return await http<Response<number | string>>('/app-api/system/notify/message/get-unread-count', {
    method: 'post'
  })
}

export const getUnreadCountTotal = async () => {
  return await http<Response<UnreadCount>>('/app-api/member/notice/unreadCount', {
    method: 'post'
  })
}
